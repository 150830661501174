
<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      link="components/simple-tables"
    />

    <base-material-card
      icon="mdi-account-multiple"
      class="px-5 py-3"
    >
      <v-row>
        <v-col
          sm="12"
          md="8"
          lg="6"
        >
          <v-text-field
            v-model="search"
            class="pb-4 "
            label="Find People, Groups and Organisations .."
            append-icon="mdi-magnify"
            single-line
            light
            dense
            hide-details
            full-width
            :suffix="searchTotalCount > 0 ? String(searchTotalCount) : null"
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="entries"
        :options.sync="pagination"
        :server-items-length="totalEntries"
        :loading="loading"
        class="elevation-4 mb-4"
      >
        <template #top>
          <v-toolbar
            flat
            height="120"
            min-height="120"
          >
            <v-row class="my-10">
              <v-col
                class="pa-0"
                cols="12"
                sm="12"
                md="6"
                align-self="end"
                style="text-align: right"
              >
                <v-dialog
                  v-model="dialogDelete"
                  max-width="500px"
                >
                  <v-card>
                    <v-card-title class="text-h5">
                      Are you sure you want to delete this item?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeDelete"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="error"
                        @click="deleteItemConfirm"
                      >
                        Yes, delete
                      </v-btn>
                      <v-spacer />
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template
          slot="items"
          slot-scope="props"
        >
          <td>{{ props.item.displayName }}</td>
          <td class="text-right">
            {{ props.item.web }}
          </td>
          <td class="text-right">
            {{ props.item.iron }}
          </td>
        </template>
        <!-- eslint-disable-next-line -->
      <template #item.entityType="{ item }">
          <v-icon
            v-if="item.entityType === 'organization'"
            small
            class="mr-2"
          >
            mdi-domain
          </v-icon>
          <v-icon
            v-if="item.entityType === 'person'"
            small
          >
            mdi-account
          </v-icon>
          <v-icon
            v-if="item.entityType === 'group'"
            small
          >
            mdi-account-group
          </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
      <template #item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>
              mdi-account-plus
            </v-icon>
            New Person or Organization
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  v-if="editedIndex === -1"
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-model="itemType"
                    item-value="id"
                    item-text="label"
                    :items="[{id: 'persons', label: 'Person'}, {id: 'organizations', label: 'Organizations'}, {id: 'groups', label: 'Groups'}]"
                    label="What kind of entry you d like to create?"
                  />
                </v-col>
                <v-col
                  v-if="itemType === 'persons'"
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editedItem.firstName"
                    label="First name"
                  />
                </v-col>
                <v-col
                  v-if="itemType === 'persons'"
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editedItem.lastName"
                    label="Last name"
                  />
                </v-col>
                <v-col
                  v-if="itemType !== 'persons'"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="editedItem.name"
                    :label="itemType !== 'groups' ? 'Organization name' : 'Group name'"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="editedItem.web"
                    label="web"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </base-material-card>
  </v-container>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    data () {
      return {
        search: '',
        totalEntries: 0,
        entries: [],
        loading: true,
        pagination: {},
        headers: [
          { text: 'Typ', value: 'entityType', align: 'start', sortable: false },
          {
            text: 'Display Name',
            align: 'left',
            sortable: false,
            value: 'displayName',
          },
          { text: 'Web', value: 'web', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        editedIndex: -1,
        itemType: 'persons',
        editedItem: {
          id: '',
          firstName: '',
          lastName: '',
          name: '',
          web: '',
        },
        defaultItem: {
          firstName: '',
          lastName: '',
          name: '',
          web: '',
        },
        dialog: false,
        entitySearch: '',
        dialogDelete: false,
      }
    },

    computed: {
      params (nv) {
        // console.log(nv, 'params')
        return {
          ...this.pagination,
          query: this.search,
        }
      },
      ...mapState({
        searchResults: state => state.search.result.items || [],
        searchTotalCount: state => state?.search.result.total || 0,
      }),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },
    watch: {
      params: {
        handler () {
          this.getDataFromApi().then(() => {
            this.entries = this.searchResults
            this.totalEntries = parseInt(this.searchTotalCount)
          })
        },
        deep: true,
      },
    },
    created () {
      if (this.$route.query?.search) {
        this.search = this.$route.query.search
      }
    },
    mounted () {
      this.getDataFromApi().then(() => {
        this.entries = this.searchResults
        this.totalEntries = parseInt(this.searchTotalCount)
      })
    },

    methods: {
      async initialize () {
        await this.$store.dispatch('readPersonsAndOrgas', this.typeItem)
      },
      async getDataFromApi () {
        this.loading = true
        return new Promise((resolve, reject) => {
          const {
            sortBy,
            descending,
            page,
            rowsPerPage,
            itemsPerPage,
          } = this.pagination

          const search = this.search.trim().toLowerCase()
          const params = {
            title: search,
            page: this.pagination.page - 1,
            size: itemsPerPage,
          }
          this.$store.dispatch('search/get', params)
            .then(() => {
              let items = this.searchResults
              if (search) {
                items = items.filter(item => {
                  return Object.values(item)
                    .join(',')
                    .toLowerCase()
                    .includes(search)
                })
              }

              if (this.pagination.sortBy) {
                items = items.sort((a, b) => {
                  const sortA = a[sortBy]
                  const sortB = b[sortBy]

                  if (descending) {
                    if (sortA < sortB) return 1
                    if (sortA > sortB) return -1
                    return 0
                  } else {
                    if (sortA < sortB) return -1
                    if (sortA > sortB) return 1
                    return 0
                  }
                })
              }

              if (rowsPerPage > 0) {
                items = items.slice(
                  (page - 1) * rowsPerPage,
                  page * rowsPerPage,
                )
              }

              const total = parseInt(this.searchTotalCount)

              setTimeout(() => {
                this.loading = false
                resolve({
                  items,
                  total,
                })
              }, 0)
            })
            .catch((e) => {
              console.log(e)
            })
        })
      },
      editItem (item) {
        this.editedIndex = this.entries.indexOf(item)
        this.itemType = item.entityType + 's' || 'persons'
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.entries.indexOf(item)
        this.itemType = item.entityType + 's' || 'persons'
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.entries.splice(this.editedIndex, 1)
        this.$store.dispatch('deleteEntity', this.editedItem, this.itemType)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async save () {
        if (this.itemType === 'persons') {
          delete this.editedItem.name
        } else if (this.itemType === 'organizations') {
          delete this.editedItem.firstName
          delete this.editedItem.lastName
        }
        this.editedItem.type = this.itemType
        if (this.editedIndex > -1) {
          Object.assign(this.entries[this.editedIndex], this.editedItem)
          await this.$store.dispatch('updateEntity', this.editedItem).finally(() => {
            this.initialize()
          })
        } else {
          await this.$store.dispatch('createEntity', this.editedItem).finally(() => {
            this.initialize()
          })
        }
        this.close()
      },
    },
  }
</script>
